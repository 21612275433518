import API from './core';

export const fetchAllScaleAreas = async (params?: any) => {
	try{
		return API.get('/rest/scales/scaleAreas', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postScaleArea = async (params?: any) => {
	try{
		return API.post('/rest/scales/scaleAreas', JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const putScaleArea = async (id: string, params?: any) => {
	try{
		return API.put(`/rest/scales/scaleAreas/${id}`, JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const deleteScaleArea = async (id: string) => {
	try{
		return API.delete(`/rest/scales/scaleAreas/${id}`, {})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchScaleAreaById = async (id:string) => {
	try{
		return API.get(`/rest/scales/scaleAreas/${id}`)
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}