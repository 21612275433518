import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {Loading, PageTitle, Panel, Row, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import { fetchAllEmployeesAndScaleTypesAvailable, fetchScale, postScale, postScaleAddRestrictions, postScaleNewEmployees } from '../../../../services/scale.services';


import Step1 from './Step1/Step1';
import Step3 from './Step3/Step3';
import Step2 from './Step2/Step2';
import { ComponentEvent } from '../../../../models/ComponentEvent';

import { Employee } from '../../../../models/Employee';
import { ScaleType } from '../../../../models/ScaleType';
import { Scale } from '../../../../models/Scale';

import styles from './New.module.scss';
interface NewProps{
	Container?: any
}

export const steps = ['Tipo da escala', 'Seleção de colaboradores', 'Definição de regras', 'Processamento']

const New = ({Container='div'}:NewProps) => {
	let history = useHistory();
	const [params, setParams] = useState<{id: string}>(useParams());

	const [scale, setScale] = useState<Scale>();
	const [employees, setEmployees] = useState<Employee[]>();
	const [scaleTypes, setScaleTypes] = useState<ScaleType[]>();
	const [scaleStatus, setScaleStatus] = useState('waiting');
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);
	const [currentStep, setCurrentStep] = useState(1);

	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});

	useEffect(() => {
		(async () => {
			setScaleStatus('waiting');
			
			try{
				const jsonReturned =  await fetchAllEmployeesAndScaleTypesAvailable();
				if(jsonReturned){
					
					setEmployees(jsonReturned.employees);
					setScaleTypes(jsonReturned.scaleTypes);

					if(params.id){

						const jsonReturned =  await fetchScale(params.id);
						if(jsonReturned){
							setScale(jsonReturned);
							setScaleStatus('success');
						}else{
							setScaleStatus('error');
						}

					}else{
						setScaleStatus('success');
					}

				}else{
					setScaleStatus('error');
				}
			}catch(error){
				console.log(error);
				setScaleStatus('error');
			}

		})();
	},[]);

	useEffect(()=>{
		if(scale?.stepNumber){
			if(scale.stepNumber===0 || scale.stepNumber === 4){
				if(scale.scaleStatus === 'OPTIMUM'){
					history.push(`/scalesMF/scales/${scale.id}/show`);
				}else{
					history.push('/scalesMF/scales');
				}
			}else{
				setCurrentStep(scale.stepNumber);
			}
		}
	},[scale]);

	const saveScale = async (scaleNew: Scale) => {
		try{
			setLoadingButtonSave(true);
			const jsonReturned =  await postScale(scaleNew);
			if(jsonReturned){
				console.log(jsonReturned);
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
				}else{
					setToastAlert({...toastAlert, danger: false, message:t('CHRONOS.SCALE.NEW.SAVE_SUCCESS',undefined),show: true});
					setScale(jsonReturned.data.scale);
					//Atualiza a url para o id cadastrado, pois caso não tenha id, será um novo cadastro
					history.push('/scalesMF/scales/new/'+jsonReturned.data.scale.id)
					
				}
				setLoadingButtonSave(false);

			}else{
				setToastAlert({...toastAlert, danger: true, message: t('GENERIC.TEXT.SERVER_INTERNAL_ERROR'),show: true});
				setLoadingButtonSave(false);
			}
		}catch(error){
			console.log(error);
			setToastAlert({...toastAlert, danger: true, message: t('GENERIC.TEXT.SERVER_INTERNAL_ERROR'),show: true});
		}
	}

	const addEmployeeScale = async (scaleNew: Scale, employees: string) => {
		try{
			setLoadingButtonSave(true);
			const jsonReturned =  await postScaleNewEmployees(scaleNew.id, {employeeIds: employees});
			if(jsonReturned){
				console.log(jsonReturned);
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
				}else{
					setToastAlert({...toastAlert, danger: false, message:t('CHRONOS.SCALE.NEW.ADD_EMPLOYEES_SUCCESS',undefined),show: true});
					setScale(jsonReturned.data.scale);	
				}
				setLoadingButtonSave(false);

			}else{
				setToastAlert({...toastAlert, danger: true, message: t('GENERIC.TEXT.SERVER_INTERNAL_ERROR'),show: true});
				setLoadingButtonSave(false);
			}
		}catch(error){
			console.log(error);
			setToastAlert({...toastAlert, danger: true, message: t('GENERIC.TEXT.SERVER_INTERNAL_ERROR'),show: true});
		}
	}

	const addRestrictionScale = async (scaleNew: Scale) => {
		try{
			setLoadingButtonSave(true);
			const jsonReturned =  await postScaleAddRestrictions(scaleNew.id);
			if(jsonReturned){
				console.log(jsonReturned);
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
				}else{
					setToastAlert({...toastAlert, danger: false, message:t('CHRONOS.SCALE.NEW.ADD_RESTRICTIONS_SUCCESS',undefined),show: true});
					setScale(jsonReturned.data.scale);					
				}
				setLoadingButtonSave(false);

			}else{
				setToastAlert({...toastAlert, danger: true, message: t('GENERIC.TEXT.SERVER_INTERNAL_ERROR'),show: true});
				setLoadingButtonSave(false);
			}
		}catch(error){
			console.log(error);
			setToastAlert({...toastAlert, danger: true, message: t('GENERIC.TEXT.SERVER_INTERNAL_ERROR'),show: true});
		}
	}

	const handleChangeStep = (event: ComponentEvent) => {
		if(event.name === 'scaleNew'){
			saveScale(event.value);
		}
		if(event.name === 'scaleAddEmployees'){
			addEmployeeScale(event.value.scale, event.value.employees);
		}
		if(event.name === 'scaleAddRestrictions'){
			addRestrictionScale(event.value.scale);
		}
	}

	const renderStep = () => {
		switch(currentStep){
			case 1:
				return <Step1 scaleTypes={scaleTypes!} 
							loadingButtonSave ={loadingButtonSave}
							handleChange={(event: ComponentEvent) => handleChangeStep(event)} />
			case 2:
				return <Step2 scale={scale!} 
							employees={employees!}
							loadingButtonSave ={loadingButtonSave}
							handleChange={(event: ComponentEvent) => handleChangeStep(event)}/>
			case 3:
				return <Step3 scale={scale!} 
							loadingButtonSave={loadingButtonSave}
							handleChange={(event: ComponentEvent) => handleChangeStep(event)}/>	
			default:
				return <Step1 scaleTypes={scaleTypes!} 
							loadingButtonSave ={loadingButtonSave}
							handleChange={(event: ComponentEvent) => handleChangeStep(event)} />
		}
	}
	return(
		<Container className={styles['scale-new-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CHRONOS.SCALE.NEW.TITLE')} />
				</Row.Item>
			</Row>
			{scaleStatus === 'waiting'  &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{(scaleStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{scaleStatus === 'success' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						{renderStep()}
					</Row.Item>
				</Row>
			}

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}

		</Container>
	);

}

export default New;