import React, { useEffect, useState } from 'react';

import { Button, ColorSelect, Input, Panel, Row, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './Form.module.scss';
import { ScaleArea } from '../../../../models/ScaleArea';
import { ComponentEvent } from '../../../../models/ComponentEvent';

interface FormProps{
	Container?: any,
	title: string,
	handleOperation?: Function,
	renderPanel: boolean,
	loading?: boolean,
	scaleArea?: ScaleArea
}
const Form = ({title ,renderPanel=false, loading=false, handleOperation, scaleArea={id:'',name:'', color:'', abbreviation: ''}, Container='div' }: FormProps) => {

	const [sa, setSa] = useState<ScaleArea>(scaleArea);
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});

	useEffect(() => {
		setLoadingButtonSave(loading);
	},[loading]);
	const saveScaleArea = async () => {

		if(!sa.name || sa.name ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE_AREA.NEW.INFORM_NAME',undefined),show: true});
			return;
		}
		if(!sa.color || sa.color ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE_AREA.NEW.INFORM_COLOR',undefined),show: true});
			return;
		}
		if(!sa.abbreviation || sa.abbreviation.trim() ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE_AREA.NEW.INFORM_ABBREVIATION',undefined),show: true});
			return;
		}
		if(sa.abbreviation?.length > 5){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE_AREA.NEW.MAX_SIZE_ABBREVIATION',undefined),show: true});
			return;
		}
		if(handleOperation){
			handleOperation({name: 'scaleAreaFilledData', status: 'success', message:'', value: sa});
		}

	}

	const renderForm = () => {
		return(
			<Container className={styles['form-scale-area']}>
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Input 
							id='name'
							fullWidth
							title='Nome'
							name='name'
							value={sa.name}
							handleChange = {(event: React.ChangeEvent<HTMLInputElement>) => setSa({...sa, name: event.target.value})}/>
					</Row.Item>
					<Row.Item sm={12} md={12} lg={12}>
						<Input 
							id='abbreviation'
							fullWidth
							title='Sigla (máximo 5 letras)'
							name='abbreviation'
							value={sa.abbreviation}
							handleChange = {(event: React.ChangeEvent<HTMLInputElement>) =>  setSa({...sa, abbreviation: event.target.value})}/>
					</Row.Item>
					<Row.Item sm={12} md={12} lg={12}>
						<ColorSelect
							value={sa.color}
							label='Cor para identificação da área'
							handleChange = {(event: ComponentEvent) =>  setSa({...sa, color: event.value.color})}/>
					</Row.Item>
					<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['form-scale-area-actions']}>
							<Button primary md 
									title={t('GENERIC.BUTTON.SAVE.TEXT',undefined)} 
									icon='save'
									loading={loadingButtonSave} 
									action={() => saveScaleArea()} />

							<Button danger md 
									icon="times" 
									title={t('GENERIC.TEXT.CANCEL',undefined)}
									action={()=> handleOperation!({name: 'scaleAreaCancel', status: 'success', message:''}) } />
						</Container>
					</Row.Item>
				</Row>
			</Container>
			
		);
	}
	return (
		<Container className={styles['scale-area-new-rt']}>
			{renderPanel ?
				<Panel title={title} noTitleBorder>
					{renderForm()}
				</Panel>
				:
				renderForm()
			}
			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default Form;