import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Loading, Panel, Row, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import Form from '../Form/Form';

import { ScaleArea } from '../../../../models/ScaleArea';
import { fetchScaleAreaById, putScaleArea } from '../../../../services/scaleArea.services';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './Edit.module.scss';
import { ComponentEvent } from '../../../../models/ComponentEvent';

interface EditProps{
	Container?: any,
	renderPanel?: boolean,
	redirectUrl?: string,
	handleOperation?: Function,
	scaleAreaId?: string
}
const Edit = ({scaleAreaId, renderPanel=true, redirectUrl, handleOperation, Container='div'}: EditProps) => {

	let history = useHistory();

	const [scaleArea, setScaleArea] = useState<ScaleArea>({id: '', name: '', color:'', abbreviation: ''});
	const [scaleAreaStatus, setScaleAreaStatus] = useState<string>('waiting');
	const [loading, setLoading] = useState(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});

	const [params, setParams] = useState<{id: string}>(useParams());

	useEffect(()=>{
		(async () => {
			setScaleAreaStatus('waiting');
			try{
				const jsonReturned =  await fetchScaleAreaById(scaleAreaId?scaleAreaId:params.id);
				if(jsonReturned){
					setScaleArea(jsonReturned);
					
					setScaleAreaStatus('success');

				}else{
					setScaleAreaStatus('error');
				}
			}catch(error){
				setScaleAreaStatus('error');
				console.log(error);
			}

		})();
	}, []);
	const handle = async (event: ComponentEvent) => {
		if(event.name==='scaleAreaFilledData' && event.status==='success'){
			setLoading(true);
			setScaleArea(event.value);
			try{
				const jsonReturned =  await putScaleArea(scaleArea.id, {id:scaleArea.id, name: event.value.name, abbreviation: event.value.abbreviation, color: event.value.color});
				if(jsonReturned){
					if(jsonReturned.status==='ERROR'){
						setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
						setLoading(false);
					}else{
						setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
						setTimeout(() => { 
							setLoading(false); 
							if(handleOperation){
								handleOperation({
												status: 'success', 
												name: 'scaleAreaUpdated', 
												message: '',
												value: jsonReturned.data
											});
							}else if(redirectUrl){
								history.push(redirectUrl) 
							}

						}, 3000);
					}
				}else{
					setToastAlert({...toastAlert, danger: true, message: t('GENERIC.TEXT.SERVER_INTERNAL_ERROR'),show: true});
					setLoading(false);
				}
			}catch(error){
				console.log(error);
			}
		}else{
			if(handleOperation){
				handleOperation({
					status: 'success', 
					name: 'canceled',
					message: ''
				});
			}
		}

	}

	return (
		<Container className={styles['scale-area-edit-rt']}>
			{(scaleAreaStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(scaleAreaStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING',undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{(scaleAreaStatus === 'success') &&
				<>
					<Form title='Edição de Área'
							renderPanel={renderPanel} 
							scaleArea={scaleArea}
							loading={loading} 
							handleOperation={(event: ComponentEvent) => handle(event)} />

					{toastAlert.show &&
						<Toast 
							success={!toastAlert.danger}
							danger={toastAlert.danger}
							title={t('GENERIC.TEXT.OPERATION',undefined)}
							icon={toastAlert.danger?'exclamation-circle':'check'}
							positionTopRight
							closeChange={() => setToastAlert({...toastAlert, show: false})}
							message={toastAlert.message} 
							showTime={5000}/>
					}
				</>
			}

		</Container>
	);
}

export default Edit;