import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { buildSelectOptions } from '../../../../../utils/SelectUtil';

import { postScaleNewHours } from '../../../../../services/scaleEmployee.services';

import styles from './New.module.scss';
import { AvatarName, Button, Radio, Row, Select, Switch, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { colors } from '@optimuminterfaces/revex-react-components/dist/utils/ColorUtil';

interface NewProps{
	Container?: any,
	scaleId: string,
	employee: any,
	date: string,
	scaleAreas: any[],
	workingHours: any[],
	handleChange: Function,
}


const New = ({employee, scaleId, date, scaleAreas, workingHours, handleChange ,Container='div'}: NewProps) =>{
	const dateFormated = formatLocalDate(date,true,'YYYY/MM/DD','DD/MM/YYYY');
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);
	const [scaleNewHour, setScaleNewHour] = useState<{type:string, scaleArea: any, workingHour: any, isOff: boolean}>({type:'', scaleArea: {value:''}, workingHour: {value: ''}, isOff: false});
	const [scaleAreasOptions, setScaleAreasOptions] = useState<any[]>();
	const [workingHoursOptions, setWorkingHoursOptions] = useState<any[]>();
	const [scaleValidationErrors, setScaleValidationErrors] = useState<any>();
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});

	let endDateDisplay = moment(date,'YYYY-MM-DD').add(5, 'days').format('YYYY-MM-DD');
	const endDateFormated = formatLocalDate(endDateDisplay,true,'YYYY/MM/DD','DD/MM/YYYY');

	useEffect(() => {
		setScaleAreasOptions(buildSelectOptions(scaleAreas,'id','name'));
		setWorkingHoursOptions(buildSelectOptions(workingHours,'singleLine','literal'));
	},[scaleAreas,workingHours]);


	const changeSelectWorkingHours = (key: string) => {
        let workingHours = [...workingHoursOptions!];

        for (var i = 0; i < workingHours.length; i++) {
            if (workingHours[i].key === key) {
                workingHours[i].selected = true;
                setScaleNewHour({...scaleNewHour, workingHour: workingHours[i]});
				//setFilters({...filters, month: months[i].key})
            } else {
                workingHours[i].selected = false;
            }
        }

        setWorkingHoursOptions(workingHours);

    }

	const changeSelectScaleArea = (key: string) => {
        let scaleAreas = [...scaleAreasOptions!];

        for (var i = 0; i < scaleAreas.length; i++) {
            if (scaleAreas[i].key === key) {
                scaleAreas[i].selected = true;
                setScaleNewHour({...scaleNewHour, scaleArea: scaleAreas[i]});
				//setFilters({...filters, month: months[i].key})
            } else {
                scaleAreas[i].selected = false;
            }
        }

        setScaleAreasOptions(scaleAreas);

    }

	const renderValidationErrors = () => {
		let listErrors:any = [];
		return (
			<Container className={styles['scale-validation-box']}>
				<Container className={styles['scale-validation-box-title']}>Escala com incidentes/infrações</Container>
				<ul>
					{scaleValidationErrors.map((error:any) => {
						if(!listErrors.includes(error.description)){
							//Adiciona a lista de controle
							listErrors.push(error.description);
							return <li>{error.description} {error.startDate? ` Periodo: ${formatLocalDate(error.startDate, true, 'YYYY-MM-DD','DD/MM/YYYY')} - ${formatLocalDate(error.endDate, true, 'YYYY-MM-DD','DD/MM/YYYY')}`:''}</li>
						}
					})}
				</ul>
			</Container>
		)
	}

	const saveAndSendEvent = async () => {

		if(!scaleNewHour.type || scaleNewHour.type ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.NEW_HOURS.INFORM_TYPE',undefined),show: true});
			return;
		}
        //Validar apenas se não for lançamento de folga
        if(!scaleNewHour.isOff){
            if(!scaleNewHour.scaleArea || scaleNewHour.scaleArea.value ===''){
                setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.NEW_HOURS.INFORM_AREA',undefined),show: true});
                return;
            }
            if(!scaleNewHour.workingHour || scaleNewHour.workingHour.value ===''){
                setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.NEW_HOURS.INFORM_HOURS',undefined),show: true});
                return;
            }
        }
		setLoadingButtonSave(true);
		let endDate = date;
		if(scaleNewHour.type==='nextFiveDays'){
			
			endDate = endDateDisplay;
		}
		//Limpa errors de validação
		setScaleValidationErrors(undefined);

        let params = {
            scaleId: scaleId, 
            employeeId: employee.id, 
            hours: scaleNewHour.workingHour.key, 
            scaleAreaId: scaleNewHour.scaleArea.key,
            startDate: date,
            endDate: endDate,
            isOff: scaleNewHour.isOff
        };

        if(scaleNewHour.isOff){
            delete params.scaleAreaId;
            delete params.hours;
        }

		try{
			const jsonReturned =  await postScaleNewHours(params);
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					if(jsonReturned.data?.scaleValidationErrors){
						setScaleValidationErrors(jsonReturned.data.scaleValidationErrors['0']);
					}
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setLoadingButtonSave(false);
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					
					if(handleChange){
						handleChange({
										status: 'success', 
										name: 'scaleNewHourSave', 
										message: '',
										value: jsonReturned.data
									});
					}

					setTimeout(() => { 
						handleChange({
							status: 'success', 
							name: 'scaleNewHourCancel', 
							message: '',
							value: jsonReturned.data
						});
						setLoadingButtonSave(false); 

					}, 1000);
				}
			}else{

				setToastAlert({...toastAlert, danger: true, message: 'Internal error',show: true});
				setLoadingButtonSave(false);
			}
		}catch(error){
			console.log(error);
		}
	}

	return (
		<Container className={styles['scale-hour-new-rt']} >
			
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					{scaleValidationErrors && renderValidationErrors()}
				</Row.Item>
				<Row.Item sm={12} md={12} lg={12}>
					<AvatarName size='m' 
									color=''
									text={employee.name} 
									subText={employee.occupation}
									/>
				</Row.Item>
                <Row.Item sm={12} md={12} lg={12}>
                    <Switch textOff="N" 
                            textOn="S" 
                            text="Lançar folga?" 
                            colorOn={colors['blue-color']}
                            value={scaleNewHour.isOff} 
                            sm 
                            handleChange={(e) => { setScaleNewHour({...scaleNewHour, isOff: e.target.checked})}}/>
				</Row.Item>
                {!scaleNewHour.isOff &&
                    <>
                        <Row.Item sm={12} md={12} lg={12}>
                            <Select medium 
                                    fullWidth
                                    value={scaleNewHour.scaleArea.value}
                                    name='area' 
                                    title='Areas' 
                                    options={scaleAreasOptions?scaleAreasOptions:[]} handleChange={changeSelectScaleArea}/>
                        </Row.Item>
                        <Row.Item sm={12} md={12} lg={12}>
                            <Select medium 
                                    fullWidth
                                    value={scaleNewHour.workingHour.value}
                                    name='hours' 
                                    title='Horário de Traballho' 
                                    options={workingHoursOptions?workingHoursOptions:[]} handleChange={changeSelectWorkingHours}/>
                        </Row.Item>
                    </>
                }
				<Row.Item sm={12} md={12} lg={12}>
                	<Radio name="type"
                        title="Tipo do Lançamento"
						inline
                        disableAll={false} small={false} medium
                        options={[{key:'oneDay', value: `Apenas em ${dateFormated}` },{key:'nextFiveDays', value: `De ${dateFormated} até ${endDateFormated} (6 dias)` }]}
                        handleChange={(e) => setScaleNewHour({...scaleNewHour, type: e.target.value})} />
				</Row.Item>

				<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['scale-hour-new-actions']}>
							<Button md 
									icon="times" 
									outlined
									title={t('GENERIC.TEXT.CANCEL',undefined)}
									action={()=> handleChange!({name: 'scaleNewHourCancel', status: 'success', message:''}) } />

							<Button primary md 
									title={t('GENERIC.BUTTON.SAVE.TEXT',undefined)} 
									icon='save'
									loading={loadingButtonSave} 
									action={() => saveAndSendEvent()} />
						</Container>
				</Row.Item>
			</Row>
			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default New;