import API from './core';


export const postGetScale = async (params?: any) => {
	try{
		return API.post('/rest/scales/scaleEmployees/show', JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postScaleNewHours = async (params?: any) => {
	try{
		return API.post('/rest/scales/scaleEmployees/addHours', JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {

				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const putScaleDeleteHour = async ( params?: any) => {
	try{
		return API.put(`/rest/scales/scaleEmployees/deleteHour`, JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postScaleNewEmployees = async (scaleId:string, params?: any) => {
	try{
		return API.post(`/rest/scales/scaleEmployees/${scaleId}/addEmployees`, JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
			
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchAllEmployeeAndAreaAndHoursAvailable = async (params?: any) => {
	try{
		return API.get('/rest/scales/scaleEmployees/getEmployeeAndAreaAndHoursAvailable', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postCopyScale = async (params?: any) => {
	try{
		return API.post(`/rest/scales/scaleEmployees/copyScale`, JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
			
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}


export const getMy = async (params?: any) => {
	try{
		return API.get('/rest/scales/scaleEmployees/getMy', {params})
		.then(
			response => {

				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postGetScalePDF = async (params?: any) => {
	try{
		return API.post('/rest/scales/scaleEmployees/generateScalePDF', 
						JSON.stringify(params), 
						{headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer' })
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}