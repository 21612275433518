import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Loading, PageTitle, Panel, Row, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import { fetchAllScales } from '../../../../services/scale.services';
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import styles from './List.module.scss';
import { Scale } from '../../../../models/Scale';
import { translateStatus } from '../../../../utils/ScaleUtil';
interface ListProps{
	Container?: any
}
const List = ({Container='div'}: ListProps) => {
	let history = useHistory();

	const [scaleStatus, setScaleStatus] = useState('waiting');
	const [scales, setScales] = useState<Scale[]>();

	useEffect(()=> {
		(async () => {
			setScaleStatus('waiting');
			try{
				const jsonReturned =  await fetchAllScales();
				if(jsonReturned){
					setScales(jsonReturned.scales);
					setScaleStatus('success');
				}else{
					setScaleStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[]);

	const handleColumnRender = (columnValue:any, columnName:string, columnIndex:number, row: Scale) => {
		switch(columnName){
			case 'actions':
				return (
					<Container className={styles['scale-area-actions']}>
						{row.stepNumber === 4 && 
							<Button xs 
								className={styles['button-action']} 
								warn 
								icon='eye'
								action = {() => history.push(`/scalesMF/scales/${row.id}/show`)}
								title={t('GENERIC.TEXT.VIEW')}/>
						}
						{row.stepNumber !== 4 && 
							<Button xs 
								className={styles['button-action']} 
								icon='check'
								primary
								action = {() => history.push(`/scalesMF/scales/new/${row.id}`)}
								title={t('GENERIC.TEXT.CONTINUE')}/>
						}
					</Container>
				)
			case 'created':
				return formatLocalDate(columnValue, true, 'yyyy-MM-DDTHH:mm:ss.sss','DD/MM/yyyy HH:mm:ss');
			case 'TYPE': 
				return row.scaleType==='MANUAL'?t('GENERIC.TEXT.SCALE_WITHOUT_OPTIMIZATION'):row.scaleType;
			case 'PERIOD': 
				return row.scaleType==='MANUAL'?t('GENERIC.TEXT.YEARLY'):(row.startDate + ' ' +row.endDate);
			case 'scaleStatus': 
				return translateStatus(columnValue);
            case 'ROSTERS': 
                return row.scaleType==='MANUAL'?'1':'-';
			default:
				return columnValue;
		}
	}

	return (
		<Container className={styles['scale-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CHRONOS.SCALE.LIST.TITLE')} />
				</Row.Item>
			</Row>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel noTitleBorder>
						<Row>
							<Row.Item lg={12} md={12} sm={12}>
								<Container className={styles['group-buttons']}>
									<Button primary md
										title={t('CHRONOS.SCALE.LIST.NEW_SCALE',undefined)}
										icon="plus"
										action={() => { history.push('/scalesMF/scales/new')}} />
								</Container>
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{(scaleStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(scaleStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{scales &&  scaleStatus === 'success' && scales.length === 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel noPadding>
							<TextInformation 
								icon="search"
								text={t('CHRONOS.SCALE.LIST.SCALE_NOT_FOUND',undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{scales &&  scaleStatus === 'success' && scales.length > 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel title={t('CHRONOS.SCALE.LIST.TITLE')} noPadding noTitleBorder>
							<Container className={styles['list']}>
								<TableListPagination list={scales}
									headers={[t('GENERIC.TEXT.SCALE'),
											t('SCALE.TEXT.SCALE_TYPE'), 
											t('SCALE.INDEX.TEXT.LAST_PERIOD'), 
											t('GENERIC.TEXT.STATUS'),
											t('GENERIC.TEXT.SCALES'), 
											t('GENERIC.TEXT.CREATION_DATE'),
											t('GENERIC.TEXT.OPTIONS')]}
									columns={['name','TYPE','PERIOD','scaleStatus','ROSTERS','created','actions']}
									handleColumnRender={handleColumnRender} />
							</Container>
						</Panel>
					</Row.Item>
				</Row>
			}
		</Container>
	);
}

export default List;