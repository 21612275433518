import API from './core';

export const fetchAllScales = async (params?: any) => {
	try{
		return API.get('/rest/scales/scales', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchAllEmployeesAndScaleTypesAvailable = async (params?: any) => {
	try{
		return API.get('/rest/scales/scales/getEmployeesAndScaleTypesAvailable', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchScale = async (id: string, params?: any) => {
	try{
		return API.get(`/rest/scales/scales/${id}`, {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postScale = async (params?: any) => {
	try{
		console.log(params)
		return API.post('/rest/scales/scales', JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postScaleNewEmployees = async (scaleId:string, params?: any) => {
	try{
		return API.post(`/rest/scales/scales/${scaleId}/addEmployees`, JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
			
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postScaleAddRestrictions = async (scaleId:string, params?: any) => {
	try{
		return API.post(`/rest/scales/scales/${scaleId}/addRestrictions`, JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
			
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}