import React from 'react';
import { HashHistory } from 'history';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/templates/App/App';

declare global {
    interface Window { 
        renderChronos: any;
        unmountChronos: any;
    }
}

window.renderChronos = (containerId: string, history: HashHistory, store: any): Boolean => {
    ReactDOM.render(
        <App history={history} />,
        document.getElementById(containerId),
    );
    return true
};

window.unmountChronos = (containerId: string):Boolean => {
    const domElement =  document.getElementById(containerId) as Element;
    ReactDOM.unmountComponentAtNode(domElement);
    return true
};

if (document.getElementById('chronos-container') == null) {
    ReactDOM.render(
        <React.StrictMode>
            <App showMenu={process.env.NODE_ENV === 'development'}/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
