import React, { useEffect, useState } from 'react';
import { ScaleType } from '../../../models/ScaleType';

import styles from './SelectScaleType.module.scss';

interface SelectScaleTypeProps{
	Container?:any,
	className?: string,
	handleChange: Function,
	title?: string,
	scaleTypes: ScaleType[]
}
const SelectScaleType = ({scaleTypes, title, className, handleChange, Container='div'}: SelectScaleTypeProps) => {
	const [scaleTypeSelected, setScaleTypeSelected] = useState('');

	useEffect(() => {
		if(scaleTypeSelected && scaleTypeSelected !== ''){
			if(handleChange){
				handleChange({name: 'changeScaleType', message: '', status: 'success', value: {scaleTypeId: scaleTypeSelected}})
			}
		}
	},[scaleTypeSelected]);
	return(
		<Container className={styles['select-scale-type-rt'] +' '+ (className||'')}>
			{title && <Container className={styles['select-scale-type-title']}>{title}</Container>}
			<Container className={styles['select-scale-type-container']}>
			{scaleTypes.map((scaleType, index) => 
				<Container key={index} className={`${styles['select-scale-type-box']} ${scaleTypeSelected===scaleType.id? styles['selected']:''}` } onClick={()=> { setScaleTypeSelected(scaleType.id)}}>
					<Container className={styles['select-scale-type-box-title']}>{scaleType.name}</Container>
					<Container className={styles['select-scale-type-box-body']}>
						<ul>
							{scaleType.description?.split(';').map((d,index) => 
								<li key={scaleType.id+index}>{d}</li>
							)}
						</ul>
					</Container>
				</Container>
			)}
			</Container>

		</Container>
	);
}

export default SelectScaleType;