import React, { useEffect, useState } from 'react';
import ApplicationRouter from '../../../router';

import '../../../configs/awesomeFontLibrary';

import { Loading, Sidebar } from '@optimuminterfaces/revex-react-components/dist/components/index';

import './App.scss';
import styles from './App.module.scss';
import { fetchEnvironment, setEnvironment } from '../../../services/auth.services';
export interface AppProps {
    history?: any,
	showMenu?: boolean,
	Container?: any
}

const renderSidebar = () => {
	
	return (
		<Sidebar>
			<Sidebar.Menu name='Dashboard' icon='tachometer-alt' url='#/scalesMF'/>
			<Sidebar.Menu name='Escala' icon='calendar-alt'>
				<Sidebar.SubMenu
	                    icon='calendar-week'
	                    // url='#/scalesMF/scales/9805ebe8-928f-4b51-b313-021a33de4282/show'
						url='#/scalesMF/scales'
	                    title='Visualizar'
	                    subtitle='Gerenciar, visualizar e atualizar as escalas' />
				<Sidebar.SubMenu
	                    icon='map-marked-alt'
	                    url='#/scalesMF/scaleAreas'
	                    title='Áreas'
	                    subtitle='Listagem das áreas de trabalho para os colaboradores' />
			</Sidebar.Menu>
		</Sidebar>
	)
}

function App({history, showMenu, Container='div'}: AppProps) {
	const [environmentLoaded, setEnvironmentLoaded] = useState(false);
	const [isAuth, setIsAuth] = useState(false);

	useEffect(() => {
		(async () => {
			try{
				setEnvironmentLoaded(false);
				const jsonReturned =  await fetchEnvironment();
				if(jsonReturned){
					setEnvironment(jsonReturned);
					setEnvironmentLoaded(true);
					setIsAuth(true);
				}else{
					setEnvironmentLoaded(true);
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[]);
	
    return (
		<>
			<div className={`${styles['app']} ${(showMenu && isAuth)?styles['sidebar']:''} chronos-app`}>
				{environmentLoaded === true &&
					<>
						{(showMenu && isAuth) &&
							<Container className={styles['container-sidebar']}>
								{renderSidebar()}
							</Container>
						}
						<Container className={styles['container-body']}>
							<ApplicationRouter history={history}/>
						</Container>
					</>
				}
				{environmentLoaded === false &&
					<Loading text='Carregando configurações do módulo'/>
				}
			</div>
		</>
    );
}

export default App;