import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Loading, PageTitle, Panel, Row, Select, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import { downloadPdf } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import TableListScale from '../../../organism/Scale/TableListScale/TableListScale';
import { buildSelectOptions } from '../../../../utils/SelectUtil';
import { ComponentEvent } from '../../../../models/ComponentEvent';
import { ScaleTranslate } from '../../../../models/ScaleTranslate';

import { fetchAllEmployeeAndAreaAndHoursAvailable, postGetScale, postGetScalePDF } from '../../../../services/scaleEmployee.services';

import styles from './Show.module.scss';
interface ShowProps {
	Container?: any
}
const Show = ({Container= 'div'}: ShowProps) => {

	const [dates, setDates] = useState<any[]>();
	const [status, setStatus] = useState<string>('waiting');
	const [scaleEmployees, setScaleEmployees] = useState<ScaleTranslate[]>();
	const [params, setParams] = useState<{id: string}>(useParams());
	const [employees, setEmployees] = useState();
	const [scaleAreas, setScaleAreas] = useState();
	const [wokingHours, setWorkingHours] = useState();

	const [optionsEmployees, setOptionsEmployees] = useState<any[]>();
	const [optionsScaleAreas, setOptionsScaleAreas] = useState<any[]>();
	const [optionsOccupations, setOptionsOccupations] = useState<any[]>();

	const [requestParams, setRequestParams] = useState<any>();
	const [loadingDownloadScalePDF, setLoadingDownloadScalePDF] = useState<boolean>(false);
	
	useEffect(() => {
		(async () => {
			setStatus('opening');
			
			try{
				const jsonReturned =  await fetchAllEmployeeAndAreaAndHoursAvailable();
				if(jsonReturned){
					
					setEmployees(jsonReturned.employees);
					setScaleAreas(jsonReturned.scaleAreas);
					setWorkingHours(jsonReturned.workingHours);

					setOptionsEmployees(buildSelectOptions(jsonReturned.employees, 'id', 'name'));
					setOptionsScaleAreas(buildSelectOptions(jsonReturned.scaleAreas, 'name', 'name'));
					setOptionsOccupations(buildSelectOptions(jsonReturned.occupations, 'id', 'name'));

					setStatus('initialDataReturned');

				}else{
					setStatus('error');
				}
			}catch(error){
				console.log(error);
				setStatus('error');
			}

		})();
	},[]);



	useEffect(() => {
		loadData();
	},[dates]);

	const loadData = async () => {

		if(dates && dates.length > 0){

			let p = {...requestParams};

			let employeeIdsSelected:string[] = [];
			optionsEmployees?.forEach(e => {
				if(e.selected){
					employeeIdsSelected.push(e.key);
				}
			});

			let occupationIdsSelected:string[] = [];
			optionsOccupations?.forEach(e => {
				if(e.selected){
					occupationIdsSelected.push(e.key);
				}
			});

			let scaleAreaIdsSelected:string[] = [];
			optionsScaleAreas?.forEach(e => {
				if(e.selected){
					scaleAreaIdsSelected.push(e.key);
				}
			});

			if(employeeIdsSelected.length > 0){
				
                p = {...params, employees: employeeIdsSelected.join(',')};
			}

			if(occupationIdsSelected.length > 0){
                p = {...params, occupations: occupationIdsSelected.join(',')};
			}

			if(scaleAreaIdsSelected.length > 0){
                p = {...params, scaleAreas: scaleAreaIdsSelected.join(',')};
			}

            setRequestParams({...requestParams, ...params});

			setStatus('waiting');
			try{
				const jsonReturned =  await postGetScale({scaleId: params.id, startDate: dates[0].date, endDate: dates[dates.length-1].date, ...p});
				if(jsonReturned){

					setScaleEmployees(jsonReturned);
					
					setStatus('success');
				}else{
					setStatus('error');
				}
			}catch(error){
				console.log(error);
			}
		
		}
	}

	const downloadScalePDF = async () => {
		if(dates && dates.length > 0){
			try{
				setLoadingDownloadScalePDF(true);
				const jsonReturned =  await postGetScalePDF({scaleId: params.id, startDate: dates[0].date, endDate: dates[dates.length-1].date, ...requestParams});
				if(jsonReturned){
					downloadPdf(jsonReturned, 'scale.pdf');
					//setScaleEmployees(jsonReturned);
					
					//setStatus('success');
				}else{
					//setStatus('error');
				}
				setLoadingDownloadScalePDF(false);
			}catch(error){
				console.log(error);
			}		
		}
	}

	const changeSelectEmployee = (key: string) => {
		setOptionsEmployees(optionsEmployees?.map(employee => {
			return {
				key: employee.key,
				value: employee.value,
				selected: (employee.key === key ? !employee.selected : employee.selected)
			}
		}));
	};

	const changeSelectScaleArea = (key: string) => {
		setOptionsScaleAreas(optionsScaleAreas?.map(scaleArea => {
			return {
				key: scaleArea.key,
				value: scaleArea.value,
				selected: (scaleArea.key === key ? !scaleArea.selected : scaleArea.selected)
			}
		}));
	};

	const changeSelectOccupation = (key: string) => {
		setOptionsOccupations(optionsOccupations?.map(occupation => {
			return {
				key: occupation.key,
				value: occupation.value,
				selected: (occupation.key === key ? !occupation.selected : occupation.selected)
			}
		}));
	};

	const handleChange = (event: ComponentEvent) => {
		if(event.name === 'changeDates'){
			if(JSON.stringify(event.value) !== JSON.stringify(dates)){
				setDates(event.value);
			}
		}

		if(event.name === 'scaleNewHourSave' || event.name === 'scaleNewEmployeeSave' || event.name === 'scaleDeleteHourSave'){
			loadData();
		}
	}



	return (
		<Container className={styles['scale-show-rt']}>
			
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CHRONOS.SCALE.SHOW.TITLE')} />
				</Row.Item>
			</Row>
			{status === 'opening' &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{(status === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{status !== 'opening' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel title={t('GENERIC.TEXT.FILTERS')} className={styles['scale-show-filters']} noTitleBorder>
							<Row>
								<Row.Item sm={12} md={3} lg={3}>
									<Select medium 
											value={t('GENERIC.TEXT.EMPLOYEE')}
											selectItemsToShowLength={1}
											fullWidth
											multiple
											name='employee' 
											title={t('GENERIC.TEXT.EMPLOYEE')}
											options={optionsEmployees?optionsEmployees:[]} handleChange={(key) => changeSelectEmployee(key)}/>
								</Row.Item>
								<Row.Item sm={12} md={3} lg={3}>
									<Select medium 
											multiple
											value={t('GENERIC.TEXT.OCCUPATION')}
											selectItemsToShowLength={1}
											fullWidth 
											name='occupation' 
											title={t('GENERIC.TEXT.OCCUPATION')}
											options={optionsOccupations?optionsOccupations:[]} handleChange={(key) => changeSelectOccupation(key)}/>
								</Row.Item>
								<Row.Item sm={12} md={3} lg={3}>
									<Select medium 
											selectItemsToShowLength={1}
											fullWidth
											multiple
											value={'Area'}
											name='area' 
											title='Área' 
											options={optionsScaleAreas?optionsScaleAreas:[]} handleChange={(key) => changeSelectScaleArea(key)}/>
								</Row.Item>
								<Row.Item sm={12} md={1} lg={1}>
									<Button md 
											title={t('GENERIC.BUTTON.SEARCH.TEXT')}
											primary 
											icon='sync'
											loading={status==='waiting'}
											action={() => loadData()} className={styles['button-refresh']}/>
								</Row.Item>
							</Row>
						</Panel>
					</Row.Item>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel noPadding>
							<Container>
								<Row>
									<Row.Item sm={12} md={12} lg={12}>
										<TableListScale scaleEmployees={scaleEmployees!}
														status={status}
														scaleId={params.id}
														scaleAreas={scaleAreas!}
														employeesAvailable={employees!}
														workingHours={wokingHours!}
														enableAddEmployee
														enableAddHour
														options={[
														 	{name: 'Exportar Escala', icon: 'file-pdf', loading:loadingDownloadScalePDF, action: () => {downloadScalePDF()}},
														 ]}
														optionsDefault
														handleChange={(event:ComponentEvent) => handleChange(event)}/>
									</Row.Item>
								</Row>
							</Container>
						</Panel>
					</Row.Item>
				</Row>
			}
		</Container>
	)
}

export default Show;