import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import Form from '../Form/Form';

import { ScaleArea } from '../../../../models/ScaleArea';
import { postScaleArea } from '../../../../services/scaleArea.services';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './New.module.scss';
import { ComponentEvent } from '../../../../models/ComponentEvent';

interface NewProps{
	Container?: any,
	renderPanel?: boolean,
	redirectUrl?: string,
	handleOperation?: Function
}
const New = ({Container='div', renderPanel=true, redirectUrl, handleOperation}: NewProps) => {

	let history = useHistory();

	const [scaleArea, setScaleArea] = useState<ScaleArea>({id: '', name: '', color: '',abbreviation: ''});
	const [loading, setLoading] = useState(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});

	const handle = async (event: ComponentEvent) => {
		if(event.name==='scaleAreaFilledData' && event.status==='success'){
			setLoading(true);
			setScaleArea(event.value);
			try{
				const jsonReturned =  await postScaleArea({name: event.value.name, abbreviation: event.value.abbreviation, color: event.value.color});
				if(jsonReturned){
					if(jsonReturned.status==='ERROR'){
						setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
						setLoading(false);
					}else{
						setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
						setTimeout(() => { 
							setLoading(false); 
							if(handleOperation){
								handleOperation({
												status: 'success', 
												name: 'scaleAreaSave', 
												message: '',
												value: jsonReturned.data
											});
							}else if(redirectUrl){
								history.push(redirectUrl) 
							}

						}, 3000);
					}
				}else{
					setToastAlert({...toastAlert, danger: true, message: t('GENERIC.TEXT.SERVER_INTERNAL_ERROR'),show: true});
					setLoading(false);
				}
			}catch(error){
				console.log(error);
			}
		}else{
			if(handleOperation){
				handleOperation({
					status: 'success', 
					name: 'canceled',
					message: ''
				});
			}
		}

	}

	return (
		<Container className={styles['scale-area-new-rt']}>
			<Form title='Cadastro de Nova Área'
					renderPanel={renderPanel} 
					loading={loading} 
					handleOperation={(event: ComponentEvent) => handle(event)} />

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}

		</Container>
	);
}

export default New;