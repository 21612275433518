import React from 'react';

import styles from './Dashboard.module.scss';

interface DashboardProps{
	Container?: any
}
const Dashboard = ({Container='div'}:DashboardProps) => {

	
	return (
		<Container className={styles['chronos-dashboard-rt']}>
			oi
		</Container>
	)
}

export default Dashboard;