
import { library } from '@fortawesome/fontawesome-svg-core'

// import { 
//     faClock
// } from '@fortawesome/free-solid-svg-icons'

import {
	faCalendarAlt		as fasCalendarAlt,
	faCalendarWeek		as fasCalendarWeek,
	faTachometerAlt 	as fasTachometerAlt,
	faMapMarkedAlt		as fasMapMarkedAlt,
	faSearch			as fasSearch,
	faExclamationCircle as fasExclamationCircle,
	faPlus				as fasPlus,
	faSave				as fasSave,
	faTimes				as fasTimes,
	faTrash				as fasTrash,
	faUndo				as fasUndo,
	faSync				as fasSync,
	faCheck				as fasCheck,
	faEye				as fasEye,
	faQuestionCircle	as fasQuestionCircle,
	faEllipsisV			as fasEllipsisV,
	faCogs				as fasCogs,
	faFilePdf			as fasFilePdf,
	faCopy				as fasCopy,
	faTools				as fasTools,
	faUser				as fasUser

} from '@fortawesome/pro-solid-svg-icons';
import { AnyMxRecord } from 'dns';

// import {
// 	faClock as falClock, 
// } from '@fortawesome/pro-light-svg-icons';

library.add(
	fasCalendarAlt as any, fasCalendarWeek as any, fasTachometerAlt as any, fasMapMarkedAlt as any,
	fasSearch as any, fasExclamationCircle as any, fasPlus as any, fasSave as any, fasTimes as any,
	fasTrash as any, fasUndo as any, fasSync as any, fasCheck as any, fasEye as any,fasQuestionCircle as any,
	fasEllipsisV as any, fasCogs as any, fasFilePdf as any, fasCopy as any, fasTools as any, fasUser as any
);

