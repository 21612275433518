import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, DatePicker, DateTimePicker, DualList, Input, PageTitle, Panel, Row, ScaleDay, Step, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { steps } from '../New';

import styles from './Step1.module.scss';
import SelectScaleType from '../../../../atoms/SelectScaleType/SelectScaleType';
import { ComponentEvent } from '../../../../../models/ComponentEvent';
import { ScaleType } from '../../../../../models/ScaleType';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { Scale } from '../../../../../models/Scale';

interface Step1Props{
	Container?: any,
	loadingButtonSave?: boolean,
	scaleTypes: ScaleType[],
	handleChange: Function
}
const Step1 = ({scaleTypes, loadingButtonSave, handleChange ,Container='div'}:Step1Props) => {
	let history = useHistory();

	const [scale, setScale] = useState<Scale>();
	const [loadButtonSave, setLoadButtonSave] = useState(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});

	useEffect(()=>{

		setLoadButtonSave(loadingButtonSave?loadingButtonSave:false);
		
	},[loadingButtonSave]);

	const saveScale = () => {
		if(!scale?.name || scale.name ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.NEW.INFORM_NAME',undefined),show: true});
			return;
		}
		if(!scale?.scaleType || scale.scaleType ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.NEW.INFORM_TYPE',undefined),show: true});
			return;
		}
		if(!scale.startDate || scale.startDate ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.NEW.INFORM_START_DATE',undefined),show: true});
			return;
		}
		if(handleChange){
			handleChange({name: 'scaleNew', status: 'success', value: {...scale, startDate: formatLocalDate(scale.startDate,false,'DD/MM/YYYY','YYYY-MM-DD')}});
		}
	}
	return(
		<Container className={styles['scale-new-step1-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel>
						<Step stepNumber={1} 
							primary
							bottomStep
							steps={steps} />
					</Panel>
				</Row.Item>
			</Row>

			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel>
						<>
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								<SelectScaleType title='Tipo da escala' scaleTypes={scaleTypes} handleChange={(event:ComponentEvent) => setScale({...scale!, scaleType:event.value.scaleTypeId})} />
							</Row.Item>
						</Row>
						<Row>
							<Row.Item sm={9} md={9} lg={9}>
								<Input name='scaleName'
										id='scaleName'
										value={scale?.name}
										fullWidth
										handleChange={(event: React.ChangeEvent<HTMLInputElement>) => setScale({...scale!, name: event.target.value})}
										title={t('GENERIC.TEXT.SCALE_NAME')} />
							</Row.Item>
							<Row.Item sm={3} md={3} lg={3}>
								<DateTimePicker
										value={scale?.startDate}
										fullWidth
										handleChange={(data) => { setScale({...scale!, startDate: data})}}
										title={t('GENERIC.TEXT.START_DATE')} />
							</Row.Item>

							<Row.Item sm={12} md={12} lg={12}>
								<Container className={styles['form-scale-new-actions']}>
									<Button primary md 
											title={t('GENERIC.TEXT.NEXT_STEP',undefined)} 
											icon='save'
											loading={loadButtonSave} 
											action={() => saveScale()} />

									<Button danger md 
											icon="times" 
											title={t('GENERIC.TEXT.CANCEL',undefined)}
											action={()=> history.push('/scalesMF/scales')} />
								</Container>
							</Row.Item>
						</Row>
						</>
					</Panel>
				</Row.Item>
			</Row>

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}

		</Container>
	);

}

export default Step1;