import React, { useEffect, useState } from 'react';

import { CalendarView, Loading, PageTitle, Panel, Row, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { buildCalendar } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import BoxScaleDay from '../../../atoms/BoxScaleDay/BoxScaleDay';

import { ScaleTranslate } from '../../../../models/ScaleTranslate';

import { getMy } from '../../../../services/scaleEmployee.services';

import styles from './Calendar.module.scss';

interface CalendarProps{
	Container?: any
}

const Calendar = ({Container='div'}: CalendarProps) => {
	const [currentDateStr,setCurrentDateStr] = useState(new Date().toISOString().slice(0, 10));

	const [status, setStatus] = useState<string>('waiting');
	const [statusMessage, setStatusMessage] = useState<string>();
	//const [scaleEmployees, setScaleEmployees] = useState<ScaleTranslate[]>();
	const [calendarInfo, setCalendarInfo] = useState<any>(buildCalendar(new Date(), false));
	const [calendarData, setCalendarData] = useState();
	const [startDate, setStartDate] = useState<string>();
	const [endDate, setEndDate] = useState<string>();

	useEffect(() => {
		setStartDate(calendarInfo.dates[0]);
		setEndDate(calendarInfo.dates[calendarInfo.dates.length-1]);
	},[]);

	useEffect(() => {
		loadData()
		
	},[startDate]);

	const loadData = async () => {

		if(startDate && endDate){
			setStatus('waiting');
			try{
				const jsonReturned =  await getMy({startDate: startDate, endDate: endDate});
				if(jsonReturned){
					if(jsonReturned.status === 'ERROR'){
						setStatus('error');
						setStatusMessage(jsonReturned.message);
					}else{ 

						buildDataCalendar(jsonReturned.data);
						
						setStatus('success');
					}
				}else{

					setStatus('error');
				}
			}catch(error){
				console.log(error);
			}
		
		}
	}

	const buildDataCalendar = (scaleEmployees: ScaleTranslate[]) => {
		let dataArray:any = [];

		{scaleEmployees?.forEach(scaleEmployee => {
			Object.keys(scaleEmployee.dates).forEach((key:any) => {
				const absence = scaleEmployee.dates[key].absence;

				dataArray[key] = ({
                    locked: false,
                    data:
                        <>	
							{!absence && 
								scaleEmployee.dates[key].scales.map((scale:any, index:number) => (
                                    scale.hours==='folga'?
                                                <BoxScaleDay key={scaleEmployee.employee+''+key+index} 
                                                            scaleArea={t('GENERIC.TEXT.DAY_OFF') }
                                                            hours={' '} 
                                                            className={styles['box-scale-day']} 
                                                            workload={' '}
                                                            isAbsence={false}
                                                            isOff={true}/> 
                                                :
									<BoxScaleDay scaleArea={scale.scaleAreaName}
															scaleAreaAbbreviation={scale.scaleAreaAbbreviation}
															color={scale.scaleAreaColor}
															hours={scale.hours} 
															workload={scale.workload}
															key={scaleEmployee.employee+''+key+index}
															isAbsence={false}
															className={styles['box-scale-day']}/>
									
                                ))
							}
							{
								absence && 
									<BoxScaleDay key={scaleEmployee.employee+'absence'+key} 
												scaleArea={t('GENERIC.TEXT.ABSENCE') }
												scaleAreaAbbreviation='A'
												hours={absence.name} 
												className={styles['box-scale-day']} 
												isAbsence={true} />

							}
                        </>
                });
			})
		})}
		setCalendarData(dataArray);
	}

	const handleChange = (event:any) => {

		if(event.name && event.name==='calendarDates'){
			setStartDate(event.value[0]);
			setEndDate(event.value[event.value.length-1]);
			//pega data no meio do mês, para que o componente de calendarView seja renderizado corretamente
			setCurrentDateStr(event.value[event.value.length/2]);
		}
	};
	return(
		<Container className={styles['scale-calendar-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CHRONOS.SCALE.CALENDAR.TITLE')} />
				</Row.Item>
			</Row>
			{status === 'waiting' &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{(status === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={statusMessage || t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(status === 'success') && <Row>
				<Row.Item sm={12} md={12} lg={12}>
					{calendarData && <CalendarView
						dailyEventsData={calendarData}
						selectDate={currentDateStr}
						className={styles['calendar']}
						handleChange={() => {}}
						handleChanges={(event)=> handleChange(event)}/>	
					}			
				</Row.Item>
			</Row>
			}
		</Container>
	);
}

export default Calendar;