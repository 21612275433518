import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

export const translateStatus = (status:string) => {
	switch (status) {
		case 'CONSTRUCTION':
			return t('CHRONOS.SCALE.STATUS.CONSTRUCTION');
		case 'QUEUED':
			return t('CHRONOS.SCALE.STATUS.QUEUED');
		case 'PROCESSING':
			return t('CHRONOS.SCALE.STATUS.PROCESSING');
		case 'COMPLETED':
			return t('CHRONOS.SCALE.STATUS.COMPLETED');
		case 'UNSOLVED':
			return t('CHRONOS.SCALE.STATUS.UNSOLVED');
		case 'OPTIMUM':
			return t('CHRONOS.SCALE.STATUS.OPTIMUM');
		default:
			return '-';
	}
}