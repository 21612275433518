import React, { useEffect, useState } from 'react';

import { Button, Input, Loading, Modal, PageTitle, Panel, Row, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import { ScaleArea } from '../../../../models/ScaleArea';
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';

import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import { deleteScaleArea, fetchAllScaleAreas } from '../../../../services/scaleArea.services';
import New from '../New/New';
import Edit from '../Edit/Edit';

import styles from './List.module.scss';
import { ComponentEvent } from '../../../../models/ComponentEvent';
interface ListProps{
	Container?: any
}

const List = ({Container='div'}: ListProps) => {

	const [scaleAreas, setScaleAreas] = useState<ScaleArea[]>();
	const [scaleArea, setScaleArea] = useState<ScaleArea>();
	const [scaleAreaDelete, setScaleAreaDelete] = useState<ScaleArea>();
	const [scaleAreaStatus, setScaleAreaStatus] = useState<string>();
	const [nameValue, setNameValue] = useState<string>();
	const [abbreviationValue, setAbbreviationValue] = useState<string>();
	const [showModalScaleAreaNewEdit, setShowModalScaleAreaNewEdit] = useState<boolean>(false);
	const [showModalDeleteArea, setShowModalDeleteArea] = useState<boolean>(false);

	const [loadingButtonDeleteScaleArea, setLoadingButtonDeleteScaleArea] = useState<boolean>(false);

	const [filters, setFilters] = useState<{name?: string, abbreviation?: string}>();

	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});

	useEffect(() => {
		(async () => {
			setScaleAreaStatus('waiting');
			try{
				const jsonReturned =  await fetchAllScaleAreas(filters);
				if(jsonReturned){
					setScaleAreas(jsonReturned.scaleAreas);
					//setFilterIncidentes(jsonReturned.pointIncidents);
					//filterList(jsonReturned.pointIncidents, employeeValue, statusValue.key)
					setScaleAreaStatus('success');
				}else{
					setScaleAreaStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
    }, [filters]);


	const updateFilters = () => {
		setFilters({name: nameValue, abbreviation: abbreviationValue});
	}

	const showOrHideModalScaleAreaNewEdit = () => {
		setShowModalScaleAreaNewEdit(!showModalScaleAreaNewEdit);
	}

	const showOrHideModalDeleteArea = () => {
		setShowModalDeleteArea(!showModalDeleteArea);
	}

	const handleOperation = (event: ComponentEvent) => {


		if(event.name==='scaleAreaSave' && event.status==='success'){
			let sa = scaleAreas!;
			sa.push(event.value);
			setScaleAreas([...sa]);
			showOrHideModalScaleAreaNewEdit();
		}
		if(event.name==='scaleAreaUpdated' && event.status==='success'){
			updateListById(scaleArea!.id, event.value);
			setScaleArea(undefined);
			showOrHideModalScaleAreaNewEdit();
		}
		console.log(event)
		if(event.name==='canceled'){
			setScaleArea(undefined);
			showOrHideModalScaleAreaNewEdit();

		}
	}

	const updateListById = (id:string, scaleArea: ScaleArea) => {
        let newList = [];

        for (let i = 0; i < scaleAreas!.length; i++) {
            if (scaleAreas![i].id !== id) {
                newList.push(scaleAreas![i]);
            }else{
				newList.push(scaleArea);
			}
        }

        setScaleAreas([...newList]);
    };

	const removeListById = (id:string) => {
        let newList = [];

        for (let i = 0; i < scaleAreas!.length; i++) {
            if (scaleAreas![i].id !== id) {
                newList.push(scaleAreas![i]);
            }
        }

        setScaleAreas([...newList]);
    };

	const deleteArea = async () => {
		try{
			const jsonReturned =  await deleteScaleArea(scaleAreaDelete!.id);
			if(jsonReturned){
				removeListById(scaleAreaDelete!.id);
				setToastAlert({...toastAlert, message:t('CHRONOS.AREA.LIST.DELETE_CONFIRMED',undefined),show: true});
			}else{
				setScaleAreaStatus('error');
				setToastAlert({...toastAlert, message:t('CHRONOS.AREA.LIST.DELETE_ERROR',undefined), danger: true, show: true});
			}
			setLoadingButtonDeleteScaleArea(false);
			showOrHideModalDeleteArea();
		}catch(error){
			console.log(error);
		}
	}

	const handleColumnRender = ( columnValue:any, columnName:string, columnIndex:number, row: ScaleArea ) => {
		
		switch(columnName){
			case 'name':
				return <Container className={styles['employee-name']}>
							<Container className={styles['scale-area-color']} style={{backgroundColor: row.color}}></Container>
							<Container>{columnValue}</Container>
						</Container>
			case 'actions':
				return (
					<Container className={styles['scale-area-actions']}>
						<Button xs 
										className={styles['button-action']} 
										primary 
										action = {() => {setScaleArea(row); showOrHideModalScaleAreaNewEdit();}}
										title={t('GENERIC.TEXT.EDIT',undefined)}/>
						<Button xs 
										className={styles['button-action']} 
										danger
										action = {() => {setScaleAreaDelete(row); showOrHideModalDeleteArea();}}
										title={t('GENERIC.TEXT.DELETE',undefined)}/>
					</Container>
				)
			case 'created':
				return formatLocalDate(columnValue, true, 'yyyy-MM-DDTHH:mm:ss.sss','DD/MM/yyyy HH:mm:ss');
			case 'color':
				return <Container className={styles['scale-area-color']} style={{backgroundColor: columnValue}}></Container>
			default:
				return columnValue;
		}
	};

	return(
		<Container className={styles['scale-area-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CHRONOS.AREA.LIST.TITLE')} />
				</Row.Item>
			</Row>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel title={t('GENERIC.TEXT.FILTERS')} className={styles['scale-area-filters']} noTitleBorder>
						<Row>
							<Row.Item sm={12} md={5} lg={5}>
								<Input type="text" 
									fullWidth
									id='filterAreaName'
									title={t('GENERIC.TEXT.NAME')} 
									name="inputColaborador"
									value={nameValue}
									//status={inputStatus}
									handleChange={(event) => {
												setNameValue(event.target.value); 
												//filterList(incidents!, event.target.value, statusValue.key)
												}} /> 
							</Row.Item>
							<Row.Item sm={12} md={5} lg={5}>
								<Input type="text" 
									fullWidth
									id='filterAreaAbbreviation'
									title={t('GENERIC.TEXT.ABBREVIATION')} 
									name="inputColaborador"
									value={abbreviationValue}
									//status={inputStatus}
									handleChange={(event) => {
												setAbbreviationValue(event.target.value); 
												//filterList(incidents!, event.target.value, statusValue.key)
												}} /> 
							</Row.Item>
							<Row.Item sm={12} md={2} lg={2}>
									<Button 
										title={t('GENERIC.BUTTON.SEARCH.TEXT')}
										icon='search'
										action={() => {
											updateFilters()
										}}
										primary/>
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel className={styles['scale-area-filters']} noTitleBorder>
							<Row>
								<Row.Item sm={12} md={12} lg={12}>
									<Container className={styles['actions']}>
											<Button primary 
													title={t('CHRONOS.AREA.LIST.BUTTON.NEW_AREA')}
													icon='plus'
													action={() => showOrHideModalScaleAreaNewEdit() }/>
									</Container>
								</Row.Item>
							</Row>
					</Panel>
				</Row.Item>
			</Row>
			{(scaleAreaStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(scaleAreaStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{scaleAreas &&  scaleAreaStatus === 'success' && scaleAreas.length === 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel noPadding>
							<TextInformation 
								icon="search"
								text={t('CHRONOS.AREA.LIST.AREA_NOT_FOUND',undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{scaleAreas &&  scaleAreaStatus === 'success' && scaleAreas.length > 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel title={t('CHRONOS.AREA.LIST.TITLE')} noPadding noTitleBorder>
							<Container className={styles['list']}>
								<TableListPagination list={scaleAreas}
									headers={[t('GENERIC.TEXT.NAME'),t('GENERIC.TEXT.ABBREVIATION'), t('GENERIC.TEXT.CREATION_DATE'),t('GENERIC.TEXT.OPTIONS')]}
									columns={['name','abbreviation','created','actions']}
									handleColumnRender={handleColumnRender} />
							</Container>
						</Panel>
					</Row.Item>
				</Row>
			}

			{showModalScaleAreaNewEdit &&
                <Modal title={!scaleArea?'Cadastro Área':`Edição da Área - ${scaleArea.name}`} showClose bg handleClose={() => { showOrHideModalScaleAreaNewEdit() }}>
                    <Container className={styles['modal-scale-area-new-edit']}>
						{scaleArea && (
							<Edit renderPanel={false} 
								scaleAreaId={scaleArea.id} 
								handleOperation={(event:ComponentEvent) => handleOperation(event)}/>
						)}	
						{!scaleArea && (
							<New renderPanel={false} 
								handleOperation={(event:ComponentEvent) => handleOperation(event)}/>
						)}	
                    </Container>                   
                </Modal>
            }

		{showModalDeleteArea &&
                <Modal title="Confirmação" showClose xs handleClose={() => { showOrHideModalDeleteArea() }}>
                    <Container className={styles['modal-delete']}>
                        <p>{t('CHRONOS.AREA.LIST.DELETE_CONFIRMATION',undefined)} <strong>{scaleAreaDelete!.name}</strong>?</p>
                        <Button primary md
                            title={t('GENERIC.TEXT.KEEP', undefined)}
                            icon="undo"
							className={styles['modal-delete-action']}
                            action={() => { showOrHideModalDeleteArea() }} />
                        <Button danger md
                            title={t('GENERIC.TEXT.DELETE', undefined)}
                            icon="trash"
							className={styles['modal-delete-action']}
                            loading={loadingButtonDeleteScaleArea}
                            action={() => { deleteArea() }} />
                    </Container>                   
                </Modal>
            }
		</Container>
	);
}

export default List;