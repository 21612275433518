import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Panel, Row, Step, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import { steps } from '../New';
import { Scale } from '../../../../../models/Scale';

import styles from './Step3.module.scss';
interface Step3Props{
	Container?:any,
	scale: Scale,
	loadingButtonSave?: boolean,
	handleChange: Function
}
const Step3 = ({scale, loadingButtonSave, handleChange, Container='div'}: Step3Props) => {

	let history = useHistory();

	const [loadButtonSave, setLoadButtonSave] = useState(false);

	useEffect(()=>{

		setLoadButtonSave(loadingButtonSave?loadingButtonSave:false);
		
	},[loadingButtonSave]);

	const renderRestrictionOtimizationDailyScale = () => {
		return (<></>);
	}

	const renderRestrictionDaysFullTime = () => {
		return (<></>);
	}

	const renderRestrictionGroups = () => {
		return (<></>);
	}

	const renderNotRestriction = () => {
		return (
			<TextInformation 
				icon='exclamation-circle'
				text={t('GENERIC.TEXT.NO_RESTRICTIONS_MODEL')} />	
		);
	}

	const returnToListPage = () => {
		history.push('/scalesMF/scales');
	}

	const addRestrictionsInScale = () => {
		if(handleChange){
			handleChange({name: 'scaleAddRestrictions', status: 'success', value: {scale: scale} });
		}
	}

	return (
		<Container className={styles['scale-new-step3-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel>
						<Step stepNumber={3} 
							primary
							bottomStep
							steps={steps} />
					</Panel>
				</Row.Item>
			</Row>

			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel title={t('GENERIC.TEXT.RESTRICTIONS')} noTitleBorder>
						<>
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								{renderRestrictionOtimizationDailyScale()}
							</Row.Item>
						</Row>
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								{renderRestrictionDaysFullTime()}
							</Row.Item>
						</Row>
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								{renderRestrictionGroups()}
							</Row.Item>
						</Row>
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								{scale.scaleType === 'MANUAL' && renderNotRestriction()}
							</Row.Item>
						</Row>
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								<Container className={styles['form-scale-add-restrictions-actions']}>
											<Button primary md 
													title={t('GENERIC.TEXT.NEXT_STEP',undefined)} 
													icon='save'
													loading={loadButtonSave} 
													action={() => addRestrictionsInScale()} />
											<Button danger md 
													icon="times" 
													title={t('GENERIC.TEXT.CANCEL',undefined)}
													action={()=> returnToListPage()} />
										</Container>
							</Row.Item>
						</Row>
						</>
					</Panel>
				</Row.Item>
			</Row>
		</Container>
	);
}

export default Step3;