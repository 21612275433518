
import React, { useEffect, useState } from 'react';

import { Button, Row, Select, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import { postScaleNewEmployees } from '../../../../services/scaleEmployee.services';
import { buildSelectOptions } from '../../../../utils/SelectUtil';

import styles from './New.module.scss';

interface NewProps{
	Container?: any,
	scaleId: string,
	employees: any[],
	handleChange: Function,
}


const New = ({employees, scaleId, handleChange ,Container='div'}: NewProps) =>{
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);
	
	const [employeesOptions, setEmployeesOptions] = useState<any[]>();
	const [employeeValue, setEmployeeValue] = useState<any>();

	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});


	useEffect(() => {
		setEmployeesOptions(buildSelectOptions(employees,'id','name'));
	},[employees]);


	const changeSelectEmployee = (key: string) => {
        let employees = [...employeesOptions!];

        for (var i = 0; i < employees.length; i++) {
            if (employees[i].key === key) {
                employees[i].selected = true;
                setEmployeeValue(employees[i]);
				//setFilters({...filters, month: months[i].key})
            } else {
                employees[i].selected = false;
            }
        }

        setEmployeesOptions(employees);

    }

	const saveAndSendEvent = async () => {

		if(!employeeValue || employeeValue.id ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.NEW_EMPLOYEE.INFORM_EMPLOYEE',undefined),show: true});
			return;
		}

		setLoadingButtonSave(true);
		try{
			const jsonReturned =  await postScaleNewEmployees(scaleId, {employeeIds: employeeValue.key});
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setLoadingButtonSave(false);
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					
					if(handleChange){
						handleChange({
										status: 'success', 
										name: 'scaleNewEmployeeSave', 
										message: '',
										value: jsonReturned.data
									});
					}

					setTimeout(() => { 
						handleChange({
							status: 'success', 
							name: 'scaleNewEmployeeCancel', 
							message: '',
							value: jsonReturned.data
						});
						setLoadingButtonSave(false); 

					}, 1000);
				}
			}else{

				setToastAlert({...toastAlert, danger: true, message: 'Internal error',show: true});
				setLoadingButtonSave(false);
			}
		}catch(error){
			console.log(error);
		}
	}

	return (
		<Container className={styles['scale-employee-new-rt']}>
			
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Select medium 
							fullWidth
							value={employeeValue?.value}
							name='employee' 
							title='Colaborador' 
							options={employeesOptions?employeesOptions:[]} handleChange={changeSelectEmployee}/>
				</Row.Item>
				<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['scale-employee-new-actions']}>
							<Button  md 
									icon="times" 
									outlined
									title={t('GENERIC.TEXT.CANCEL',undefined)}
									action={()=> handleChange!({name: 'scaleNewEmployeeCancel', status: 'success', message:''}) } />
							<Button primary md 
									title={t('GENERIC.BUTTON.SAVE.TEXT',undefined)} 
									icon='save'
									loading={loadingButtonSave} 
									action={() => saveAndSendEvent()} />
						</Container>
				</Row.Item>
			</Row>
			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default New;