import React from 'react';

import {stringToWorkingHour} from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';

import styles from './BoxScaleDay.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVAlt } from '@fortawesome/pro-duotone-svg-icons';
import { ButtonDropdown } from '@optimuminterfaces/revex-react-components/dist/components';

interface BoxScaleDayProps{
	scaleArea: string,
	scaleAreaAbbreviation?: string,
	absenceName?: string,
	hours?: string,
	workload?: string,
	color?: string,
	isAbsence?: boolean,
    isOff?: boolean,
	Container?: any,
	className?: string,
	actions?: any[]
}
const BoxScaleDay = ({scaleArea, scaleAreaAbbreviation, absenceName, hours, workload, color, isAbsence=false, isOff= false, Container='div', actions,className}: BoxScaleDayProps) => {
	const classNameType = isOff? styles['box-scale-day-off'] : (isAbsence?styles['box-scale-day-absence']: styles['box-scale-day-work']);
	const renderHours = (hours:string) => {
		return stringToWorkingHour(hours).hours;
	}
	return (
		<Container className={`${styles['scale-day-rt']} ${className || ''}`}>
			<Container style={color ? {backgroundColor: color}:{}} className={`${styles['box-scale-day-rt']} ${classNameType}`}>
				<Container className={styles['box-scale-header']}>
					<span className={styles['box-scale-header-scale-name']}>{scaleArea}</span>
					<span className={styles['box-scale-header-scale-abbreviation']}>{scaleAreaAbbreviation}</span>
					{actions && 

						<Container className={styles['box-scale-actions']}>
							<ButtonDropdown title='' 
											xxs 
											primary
											outlined
											className={styles['action-dropdown']} 
											icon='ellipsis-v'
											iconOnly>
								<ButtonDropdown.Session >
									{actions?.map((action, index) => 
										<ButtonDropdown.Session.Item key={action.name+hours+index} title={action.name} action={()=> action.handleClick()}/>
									)}
								</ButtonDropdown.Session>
							</ButtonDropdown>
						</Container>
					}
				</Container>
				{!!absenceName &&
					<Container className={styles['box-scale-body']}>{absenceName}</Container>
				}
				{!!hours &&
					<Container className={styles['box-scale-body']}>{renderHours(hours)}</Container>
				}
				{workload && <Container className={styles['box-scale-footer']}>{workload}</Container>}
			</Container>
		</Container>
	);
}

export default BoxScaleDay;