import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, DualList, Loading, Panel, Row, Step, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { steps } from '../New';

import { Scale } from '../../../../../models/Scale';
import { Employee } from '../../../../../models/Employee';

import styles from './Step2.module.scss';
interface Step2Props{
	Container?:any,
	scale: Scale,
	loadingButtonSave?: boolean,
	employees: Employee[],
	handleChange: Function
}
const Step2 = ({scale, loadingButtonSave, employees, handleChange, Container='div'}: Step2Props) => {
	let history = useHistory();

	const [dualListOptions, setDualListOptions] = useState<any[]>();
	const [dualListOptionsSelected, setDualListOptionsSelected] = useState([]);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});
	const [loadButtonSave, setLoadButtonSave] = useState(false);
	const [stepStatus, setStepStatus] = useState('waiting');

	useEffect(() => {
		setStepStatus('waiting');
		const buildDualListOptions = (employees: Employee[]) => {
			let dualList: { key: string; selected: boolean; values: any[]; }[] = [];
			employees.forEach(employee => {
				dualList.push({key: employee.id, selected: false, values: [employee.name,employee.occupation?.name]})
			});
			
			setDualListOptions(dualList);
			
		}
		
		buildDualListOptions(employees);

		setStepStatus('success');
    }, [employees]);

	useEffect(()=>{

		setLoadButtonSave(loadingButtonSave?loadingButtonSave:false);
		
	},[loadingButtonSave]);

	const returnToListPage = () => {
		history.push('/scalesMF/scales');
	}

	// filtra todos colaboradores selecionados
	const handleChangeDualList = (returnOptions: any) => {
        let listReturn = returnOptions.filter((e:any) => e.selected === true);
        let listContains = listReturn.map((e:any) => e.key);

        setDualListOptionsSelected(listContains);
    };

	const addEmployeesInScale = () => {
		if(!dualListOptionsSelected || dualListOptionsSelected.length ===0){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.NEW.SELECT_EMPLOYEE',undefined),show: true});
			return;
		}

		if(handleChange){
			handleChange({name: 'scaleAddEmployees', status: 'success', value: {scale: scale, employees: dualListOptionsSelected.join(',')} });
		}
	}

	return (
		<Container className={styles['scale-new-step2-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel>
						<Step stepNumber={2} 
							primary
							bottomStep
							steps={steps} />
					</Panel>
				</Row.Item>
			</Row>
			{stepStatus === 'waiting'  &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{stepStatus === 'success'  &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel>
							<>
								<Row>
									<Row.Item sm={12} md={12} lg={12}>
										<DualList titleAvailable={t('GENERIC.TEXT.AVAILABLE_EMPLOYEES')}
															titleSelected={t('GENERIC.TEXT.ALLOCATED_EMPLOYEES')} 
															options={dualListOptions!}
															firstIcon="user"
															secondIcon="tools"
															handleChange={handleChangeDualList} />
									</Row.Item>
								</Row>
								<Row>
									<Row.Item sm={12} md={12} lg={12}>
										<Container className={styles['form-scale-add-employees-actions']}>
											<Button primary md 
													title={t('GENERIC.TEXT.NEXT_STEP',undefined)} 
													icon='save'
													loading={loadButtonSave} 
													action={() => addEmployeesInScale()} />
											<Button danger md 
													icon="times" 
													title={t('GENERIC.TEXT.CANCEL',undefined)}
													action={()=> returnToListPage()} />
										</Container>
									</Row.Item>
								</Row>
							</>
						</Panel>
					</Row.Item>
				</Row>
			}
			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default Step2;