import React from 'react';
import { Route, HashRouter as Router } from 'react-router-dom';
import { createHashHistory, HashHistory } from 'history';

import Dashboard from './components/pages/Dashboard/Dashboard';

import {default as ScaleShow} from './components/pages/Scale/Show/Show';
import {default as ScaleList} from './components/pages/Scale/List/List';
import {default as ScaleNew} from './components/pages/Scale/New/New';
import {default as ScaleCalendar} from './components/pages/Scale/Calendar/Calendar';

import {default as AreaList} from './components/pages/ScaleArea/List/List';
import {default as AreaNew} from './components/pages/ScaleArea/New/New';
import {default as AreaEdit} from './components/pages/ScaleArea/Edit/Edit';


const defaultHistory = createHashHistory();

interface ApplicationRouterProps {
	history : HashHistory
}

const ApplicationRouter = ({ history = defaultHistory }: ApplicationRouterProps) => {
    return(
        <Router >
            <Route exact path="/scalesMF"> 
				<Dashboard /> 
			</Route>

			<Route exact path="/scalesMF/scaleAreas"> 
				<AreaList /> 
			</Route>
			<Route exact path="/scalesMF/scaleAreas/new"> 
				<AreaNew renderPanel /> 
			</Route>
			<Route exact path="/scalesMF/scaleAreas/:id/edit"> 
				<AreaEdit renderPanel /> 
			</Route>

			<Route exact path="/scalesMF/scales"> 
				<ScaleList /> 
			</Route>
			<Route exact path="/scalesMF/scales/calendar"> 
				<ScaleCalendar /> 
			</Route>
			<Route exact path="/scalesMF/scales/new/:id?"> 
				<ScaleNew /> 
			</Route>
			<Route exact path="/scalesMF/scales/:id/show"> 
				<ScaleShow /> 
			</Route>
			
			{process.env.NODE_ENV === 'development' &&
				<Route exact path="/auth"> 
					<>Auth</>
				</Route>
			}
        </Router>
    );
}

export default ApplicationRouter;