import React, { useEffect, useState } from 'react';

import { Button, CheckBox, DualList, Loading, Row, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { postCopyScale } from '../../../../../services/scaleEmployee.services';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './CopyPaste.module.scss';
import { Employee } from '../../../../../models/Employee';

interface CopyPasteProps{
	Container?: any,
	scaleId: string,
	employees: Employee[],
	currentPeriod: number,
	periodType: string,
	handleChange: Function,
	startDate: string,
	endDate: string
}

const CopyPaste = ({scaleId, employees ,currentPeriod, periodType, startDate, endDate, handleChange ,Container='div'}: CopyPasteProps) => {
	
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});
	const [copyPasteStatus, setCopyPasteStatus] = useState('open');
	const [dualListOptions, setDualListOptions] = useState<any[]>();
	const [dualListOptionsSelected, setDualListOptionsSelected] = useState([]);
	const [acceptedTerm, setAcceptedTerm] = useState(false);

	useEffect(() =>{
		const buildDualListOptions = (employees: Employee[]) => {
			let dualList: { key: string; selected: boolean; values: any[]; }[] = [];
			employees.forEach(employee => {
				dualList.push({key: employee.id, selected: false, values: [employee.name,employee.occupation?.name]})
			});
			
			setDualListOptions(dualList);
			
		}
		
		buildDualListOptions(employees);
	},[employees])

	const saveAndSendEvent = async () => {
		setCopyPasteStatus('waiting');

		try{
			const jsonReturned =  await postCopyScale({scaleId: scaleId, employeeIds: dualListOptionsSelected.join(','), startDate: startDate, endDate: endDate});
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setCopyPasteStatus('error');
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					
					if(handleChange){
						handleChange({
										status: 'success', 
										name: 'scaleDeleteHourSave', 
										message: '',
										value: ''
									});
					}

					setCopyPasteStatus('success');
					setTimeout(() => { 
						handleChange({
							status: 'success', 
							name: 'scaleCopyPasteCancel', 
							message: '',
							value: ''
						});

					}, 2000);
				}
			}else{
				setToastAlert({...toastAlert, danger: true, message: 'Internal error',show: true});
				setCopyPasteStatus('error');
			}
		}catch(error){
			console.log(error);
		}
	}

	// filtra todos colaboradores selecionados
	const handleChangeDualList = (returnOptions: any) => {
        let listReturn = returnOptions.filter((e:any) => e.selected === true);
        let listContains = listReturn.map((e:any) => e.key);

        setDualListOptionsSelected(listContains);
    };

	const validateDataAndContinue = () => {
		if(!dualListOptionsSelected || dualListOptionsSelected.length === 0){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.COPY.SELECT_EMPLOYEE',undefined),show: true});
			return;
		}

		if(acceptedTerm === false){
			setToastAlert({...toastAlert, danger: true, message:t('CHRONOS.SCALE.COPY.ACCEPTED_TERM',undefined),show: true});
			return;
		}

		setCopyPasteStatus('question');
	}

	return (
		<Container className={styles['scale-copy-paste-rt']}>

			{copyPasteStatus === 'open' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<DualList titleAvailable={t('GENERIC.TEXT.AVAILABLE_EMPLOYEES')}
															titleSelected={t('GENERIC.TEXT.ALLOCATED_EMPLOYEES')} 
															options={dualListOptions!}
															firstIcon="user"
															secondIcon="tools"
															handleChange={handleChangeDualList} />
					</Row.Item>
					<Row.Item sm={12} md={12} lg={12}>
						<CheckBox name="Aceite"
							title="Aceito que esse processo substitua as escalas futuras dos colaboradores caso exista?"
							small={false}
							medium
							disableAll={false}
							options={[{key:'aceite', value: 'Estou ciente'}]}
							handleChange={(data) => {setAcceptedTerm(!acceptedTerm)}} />
						
					</Row.Item>

					
				</Row>
			}

			{copyPasteStatus === 'error' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<TextInformation
								iconColor='#BB5641'
								icon='exclamation-circle'
								text={'Problema ao copiar a(s) escala(s)'} />
					</Row.Item>

				</Row>
			}

			{copyPasteStatus === 'success' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<TextInformation 
								icon='check'
								iconColor={'green'}
								text={'Escala copiada'} />
					</Row.Item>

				</Row>
			}

			{copyPasteStatus === 'waiting' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Loading text={t('GENERIC.TEXT.EXECUTE_OPERATION')}/>
					</Row.Item>

				</Row>
			}

			{copyPasteStatus === 'question' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<TextInformation iconColor='#F0AD4E'
								icon='question-circle'
								text={t('CHRONOS.SCALE.COPY.CONFIRMATION', undefined, (periodType==='week'?'semana':'mês') +' '+ currentPeriod, (periodType==='week'?'semana':'mês')+ ' ' +(currentPeriod+1))} />
					</Row.Item>

				</Row>
			}

			<Row>
				<Row.Item sm={12} md={12} lg={12}>
				{(copyPasteStatus !=='waiting' && copyPasteStatus !=='success') && <Container className={styles['scale-copy-paste-actions']}>
						<Button md 
								icon="times" 
								outlined
								title={t('GENERIC.TEXT.CANCEL',undefined)}
								action={()=> handleChange!({name: 'scaleCopyPasteCancel', status: 'success', message:''}) } />
						{copyPasteStatus==='open' && <Button primary md 
								title={t('GENERIC.TEXT.CONTINUE',undefined)} 
								icon='check' 
								action={() => validateDataAndContinue()} />
						}
						{copyPasteStatus==='question' && <Button primary md 
								title={t('GENERIC.TEXT.COPY',undefined)} 
								icon='copy' 
								action={() => saveAndSendEvent()} />
						}
					</Container>
				}
				</Row.Item>
			</Row>

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);

}

export default CopyPaste;