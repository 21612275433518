import React, { useState } from 'react';

import { Button, Loading, Row, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { putScaleDeleteHour } from '../../../../../services/scaleEmployee.services';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './Delete.module.scss';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

interface DeleteHourProps{
	Container?: any,
	scaleId: string,
	employee: any,
	date: string,
	hour: string,
	handleChange: Function,
}

const Delete = ({employee, scaleId, date, hour, handleChange ,Container='div'}: DeleteHourProps) => {
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});
	const [deleteHourStatus, setDeleteHourStatus] = useState('question');

	const saveAndSendEvent = async () => {
		setDeleteHourStatus('waiting');

		try{
			const jsonReturned =  await putScaleDeleteHour({scaleId: scaleId, 
														employeeId: employee.id, 
														hour: hour,
														date: date});
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setDeleteHourStatus('error');
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					
					if(handleChange){
						handleChange({
										status: 'success', 
										name: 'scaleDeleteHourSave', 
										message: '',
										value: ''
									});
					}

					setDeleteHourStatus('success');
					setTimeout(() => { 
						handleChange({
							status: 'success', 
							name: 'scaleDeleteHourCancel', 
							message: '',
							value: ''
						});

					}, 2000);
				}
			}else{
				setToastAlert({...toastAlert, danger: true, message: 'Internal error',show: true});
				setDeleteHourStatus('error');
			}
		}catch(error){
			console.log(error);
		}
	}
	return (
		<Container className={styles['scale-hour-delete-rt']}>

			{deleteHourStatus === 'question' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<TextInformation
								iconColor='#F0AD4E'
								icon='question-circle'
								text={`Tem certeza que gostaria de remover a jornada ${hour} do colaborador ${employee.name} no dia ${formatLocalDate(date,true, 'YYYY-MM-DD','DD/MM/YYYY')}?`} />
					</Row.Item>

					<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['delete-hour-actions']}>
							<Button md 
									icon="times" 
									outlined
									title={t('GENERIC.TEXT.CANCEL',undefined)}
									action={()=> handleChange!({name: 'scaleDeleteHourCancel', status: 'success', message:''}) } />

							<Button primary md 
									title={t('GENERIC.TEXT.CONTINUE',undefined)} 
									icon='save' 
									action={() => saveAndSendEvent()} />
						</Container>
					</Row.Item>
				</Row>
			}

			{deleteHourStatus === 'error' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<TextInformation
								iconColor='#BB5641'
								icon='exclamation-circle'
								text={'Problema ao remover jornada'} />
					</Row.Item>

				</Row>
			}

			{deleteHourStatus === 'success' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<TextInformation 
								icon='check'
								iconColor={'green'}
								text={'Jornada removida'} />
					</Row.Item>

				</Row>
			}

			{deleteHourStatus === 'waiting' &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Loading text={t('GENERIC.TEXT.EXECUTE_OPERATION')}/>
					</Row.Item>

				</Row>
			}

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);

}

export default Delete;