import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { AvatarName, Button, ButtonDropdown, Loading, Modal, Row, SelectWeek, Table } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';


import BoxScaleDay from '../../../atoms/BoxScaleDay/BoxScaleDay';
import { ScaleTranslate } from '../../../../models/ScaleTranslate';
import { ComponentEvent } from '../../../../models/ComponentEvent';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import {default as DeleteHour} from '../Hour/Delete/Delete';
import {default as NewHour} from '../Hour/New/New';
import {default as NewEmployee} from '../Employee/New';

import styles from './TableListScale.module.scss';
import CopyPaste from '../Hour/CopyPast/CopyPaste';
import { Employee } from '../../../../models/Employee';



interface TableListScaleProps{
	Container?:any,
	scaleId: string,
	status: string,
	employeesAvailable: any[],
	scaleAreas: any[],
	workingHours: any[],
	enableAddHour?: boolean,
	enableAddEmployee?: boolean,
	handleChange: Function,
	options?: {name: string, icon?: string, loading: boolean, action: Function}[],
	optionsDefault?: boolean,
	scaleEmployees: ScaleTranslate[]
}
interface DateModel{
	date: string, 
	weekDay: number, 
	day: string, 
	dayWeek: string,
	daySmall: string
}


const TableListScale = ({scaleId, scaleEmployees, employeesAvailable, 
						workingHours, scaleAreas,handleChange, 
						status='waiting', enableAddHour=false, enableAddEmployee=false, 
						options, optionsDefault, Container='div'}: TableListScaleProps) => {
							
	const [dates, setDates] = useState<DateModel[]>();
	const [weekNumber, setWeekNumber] = useState<number>();
	const [listScaleEmployees, setListScaleEmployees] = useState<ScaleTranslate[]>();
	const [listEmployees, setListEmployees] = useState<Employee[]>();
	
	//Dados para operação específica
	const [operationData, setOperationData] = useState<any>();

	// Controle de abertura dos modais
	const [showModalNewHour, setShowModalNewHour] = useState(false);
	const [showModalDeleteHour, setShowModalDeleteHour] = useState(false);
	const [showModalNewScaleEmployee, setShowModalNewScaleEmployee] = useState(false);
	const [showModalConfirmCopyAndPaste, setShowModalConfirmCopyAndPaste] = useState(false);

	useEffect(() => {
		if(scaleEmployees){
			//Pega lista de employees
			let employees = scaleEmployees.map(scaleEmployee => { return {id: scaleEmployee.employee.id, 
																			name:scaleEmployee.employee.name, 
																			occupation: {name: scaleEmployee.employee.occupation}
																		} });
			setListEmployees(employees);
			setListScaleEmployees(scaleEmployees);
		}
	},[scaleEmployees]);

	const showOrHideModalNewHour = () => {
		setShowModalNewHour(!showModalNewHour);
	}

	const showOrHideModalDeleteHour = () => {
		setShowModalDeleteHour(!showModalDeleteHour);
	}

	const showOrHideModalNewScaleEmployee = () => {
		setShowModalNewScaleEmployee(!showModalNewScaleEmployee);
	}

	const showOrHideModalConfirmCopyAndPaste = () => {
		setShowModalConfirmCopyAndPaste(!showModalConfirmCopyAndPaste);
	}

	const renderTableLine = () => {
		return(
			<>
				{listScaleEmployees!.length > 0 && listScaleEmployees?.map((scaleEmployee:any,index) => (
					<Table.BodyItem key={index} className={styles['line']}>
						<Table.BodyItem.Item key={scaleEmployee.employee.name+'data'}>
						<AvatarName size='s' 
									editable={false} 
									text={scaleEmployee.employee?.name} 
									subText={scaleEmployee.employee?.occupation} 
									color={undefined}/>
						</Table.BodyItem.Item>
						{dates!.map(dt => {
							const absence = scaleEmployee.dates[dt.date]?.absence;

							return (
								<Table.BodyItem.Item key={scaleEmployee.employee.name+dt.date}>
									{!absence &&
										scaleEmployee?.dates[dt.date]?.scales?.map((day:any, index:number) => (
                                            day.hours==='folga'?
                                                <BoxScaleDay key={scaleEmployee.employee+dt} 
                                                            scaleArea={t('GENERIC.TEXT.DAY_OFF') }
                                                            hours={' '} 
                                                            className={styles['box-scale-day']} 
                                                            workload={' '}
                                                            isAbsence={false}
                                                            isOff={true}
                                                            actions={
                                                                [
                                                                    {
                                                                        name: 'Excluir', 
                                                                        handleClick: () => {
                                                                            setOperationData({employee:scaleEmployee.employee, date: dt.date, hour: day.hours}); 
                                                                            showOrHideModalDeleteHour();
                                                                        }
                                                                    }
                                                                ]
                                                            } /> 
                                                :
                                                <BoxScaleDay key={scaleEmployee.employee+day.hours+index} 
                                                                scaleArea={day.scaleAreaName}
                                                                color={day.scaleAreaColor}
                                                                hours={day.hours} 
                                                                workload={day.workload}
                                                                className={styles['box-scale-day']}
                                                                actions={
                                                                    [
                                                                        {
                                                                            name: 'Excluir', 
                                                                            handleClick: () => {
                                                                                setOperationData({employee:scaleEmployee.employee, date: dt.date, hour: day.hours}); 
                                                                                showOrHideModalDeleteHour();
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                                isAbsence={false} />
                                            
										))
										
									}
									{
										absence && 
											<BoxScaleDay key={scaleEmployee.employee+dt} 
														scaleArea={t('GENERIC.TEXT.ABSENCE') }
														absenceName={absence.name} 
														hours={absence.haveSchedule ? absence.hours : null}
														className={styles['box-scale-day']} 
														isAbsence={true} />

									}
									{!absence && enableAddHour && 
										<Container className={styles['day-action']}>
											<Button xs 
													outlined 
													icon='plus'
													action={() => { 
														setOperationData({employee:scaleEmployee.employee, date: dt.date}); 
														showOrHideModalNewHour()
													}}/>
										</Container>
									}
								</Table.BodyItem.Item>
							)
						})}
						<Table.BodyItem.Item></Table.BodyItem.Item>
						<Table.BodyItem.Item>
							<span className={styles['week-work-total']}>{scaleEmployee.sumDayWork}</span>
						</Table.BodyItem.Item>
					</Table.BodyItem>
				))}
				{listScaleEmployees!.length === 0 && (
					<Table.BodyItem className={styles['last-line-action']}>
						<Table.BodyItem.Item colSpan={10}>
							{t('CHRONOS.SCALE.SHOW.EMPTY_LIST')}
						</Table.BodyItem.Item>
					</Table.BodyItem>
				)}
			</>
		)
	}

	return (
		<Container className={styles['table-list-scale-rt']}>
			{(options || optionsDefault)  && 
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Container className={styles['table-list-scale-actions']}>
							<ButtonDropdown title={t('GENERIC.TEXT.OPTIONS')} icon='cogs' iconOnly={false} primary>
								<ButtonDropdown.Session>
									{optionsDefault &&
										<>
											<ButtonDropdown.Session.Item icon='copy' title='Copiar escala para o próximo período' action={() => showOrHideModalConfirmCopyAndPaste()}/>
											{/* <ButtonDropdown.Session.Item icon='file-pdf' title='Exportar' action={() => {}}/> */}
										</>
									}
									{options?.map(option => <ButtonDropdown.Session.Item key={option.name} icon={option.icon} title={option.name} loading={option.loading} action={()=> option.action()}/>)}
								</ButtonDropdown.Session>
							</ButtonDropdown>
						</Container>
					</Row.Item>
				</Row>
			}
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Table primary striped className={styles['table-scale']}>
						<Table.Header>
							<Table.HeaderItem className={styles['table-header-action']}>
								<Table.HeaderItem.Item ></Table.HeaderItem.Item>
								<Table.HeaderItem.Item colSpan={8}>
									<SelectWeek md
											startDate={moment.utc().format('YYYY-MM-DD')} 
											handleChange={
												(data) => { 
													handleChange({name: 'changeDates', value: data.dates }); 
													setDates(data.dates); 
													setWeekNumber(data.weekNumber);
												}
											}/>
								</Table.HeaderItem.Item>
							</Table.HeaderItem>
							{dates &&
								<Table.HeaderItem className={styles['table-header']}>
									<Table.HeaderItem.Item></Table.HeaderItem.Item>
									{dates?.map(date => (
										<Table.HeaderItem.Item key={date.date}>
											<Container className={styles['table-header-date']}>{formatLocalDate(date.date,true, 'YYYY-MM-DD','DD/MM/YYYY') }</Container>
											<Container className={styles['table-header-weekday']}>{t(`GENERIC.WEEK.${date.dayWeek.toUpperCase()}`)}</Container>
										</Table.HeaderItem.Item>
									))}
									<Table.HeaderItem.Item></Table.HeaderItem.Item>
									<Table.HeaderItem.Item>{t('GENERIC.TEXT.DAYS')}</Table.HeaderItem.Item>
								</Table.HeaderItem>
							}
						</Table.Header>
						{status==='waiting' && 
							<Table.Body>
								<Table.BodyItem>
									<Table.BodyItem.Item colSpan={9}>
									<Loading text={t('GENERIC.TEXT.LOADING')}/>
									</Table.BodyItem.Item>
								</Table.BodyItem>
							</Table.Body>}
						{dates && status==='success' && listScaleEmployees && 
							<Table.Body>
								{renderTableLine()}
								{/* Ativa adição de colaboradores a escala */}
								{enableAddEmployee? 
									<Table.BodyItem className={styles['last-line-action']}>
										<Table.BodyItem.Item colSpan={10}>
											<Button primary outlined title={t('GENERIC.TEXT.ADD_EMPLOYEE')} action={() => showOrHideModalNewScaleEmployee()}/>
										</Table.BodyItem.Item>
									</Table.BodyItem>
									: 
									<></>
								}
							</Table.Body>
						}
					</Table>
				</Row.Item>
			</Row>
			{showModalNewHour &&
                <Modal title={`Adicionar escala para o dia ${operationData.date}` }
						showClose
						md 
						handleClose={() => { showOrHideModalNewHour() }}
						className={styles['modal-new-scale']}>
                    <NewHour date={operationData.date}  
									scaleAreas={scaleAreas} 
									workingHours={workingHours}
									scaleId={scaleId}
									employee={operationData.employee} 
									handleChange={(event: ComponentEvent)=> { event.name==='scaleNewHourCancel'? showOrHideModalNewHour():handleChange(event)}}/>  
                </Modal>
        	}
			{showModalNewScaleEmployee &&
                <Modal title={`Adicionar colaborador na escala` }
						showClose
						md 
						handleClose={() => { showOrHideModalNewScaleEmployee() }}
						className={styles['modal-new-scale-employee']}>
					<NewEmployee scaleId={scaleId}
								employees={employeesAvailable} 
								handleChange={(event: ComponentEvent)=> { event.name==='scaleNewEmployeeCancel'? showOrHideModalNewScaleEmployee():handleChange(event)}}/>
                </Modal>
        	}

			{showModalDeleteHour &&
                <Modal title={`Remoção de jornada` }
						md 
						handleClose={() => { showOrHideModalDeleteHour() }}
						className={styles['modal-delete-hour']}>
					<DeleteHour scaleId={scaleId} 
								employee={operationData.employee} 
								date={operationData.date}
								hour={operationData.hour}
								handleChange={(event: ComponentEvent)=> { event.name==='scaleDeleteHourCancel'? showOrHideModalDeleteHour():handleChange(event)}}/>
                </Modal>
        	}

			{showModalConfirmCopyAndPaste &&
                <Container className={styles['modal-copy-past']}>
                    <Modal title={`Copia e Cola` }
                            lg 
                            showClose
                            handleClose={() => { showOrHideModalConfirmCopyAndPaste() }}
                            className={styles['modal-copy-paste']}>
                        <CopyPaste scaleId={scaleId} 
                                periodType='week' 
                                currentPeriod={weekNumber!} 
                                startDate={dates![0].date}
                                endDate={dates![dates!.length-1].date}
                                handleChange={(event: ComponentEvent)=> { event.name==='scaleCopyPasteCancel'? showOrHideModalConfirmCopyAndPaste():handleChange(event)}} 
                                employees={listEmployees!} />
                    </Modal>
                </Container>
        	}
		</Container>
	);
}

export default TableListScale;